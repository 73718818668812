import React, {Component} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faVideo, faVial, faChartPie, faNewspaper, faBook, faLifeRing} from '@fortawesome/free-solid-svg-icons'

class TypeIcon extends Component{

    render() {
        let faClass = '';
        switch (this.props.type) {
            case "Article":
                faClass = faNewspaper
                break;
            case 'Book':
                faClass = faBook
                break;
            case 'Video':
                faClass = faVideo
                break;
            case 'Test':
                faClass = faVial
                break;
            case 'Statistics':
                faClass = faChartPie
                break;
            default:
                faClass = faLifeRing
                break;
        }
        return (
         <FontAwesomeIcon icon={faClass} />
        )
    }
}

export default TypeIcon;