import React, { useState, useEffect, useCallback } from 'react';
import NoResult from "./NoResult";
import TypeIcon from "./TypeIcon";
import { useCookies } from 'react-cookie';

const App = () => {
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cookies, setCookie] = useCookies(['pageViewCount']);

    const fetchAllRecords = useCallback(async (filterFormula = '') => {
        setLoading(true);
        setLinks([]); // Clear existing links while loading
        setError(null);

        let moreRecords = true;
        let records = [];
        let offset = '';

        while (moreRecords) {
            try {
                const baseUrl = 'https://api.airtable.com/v0/appdoSubLbBl0SZOg/Imported%20table';
                const filterParam = filterFormula ? `?filterByFormula=${filterFormula}` : '?';
                const offsetParam = offset ? `&offset=${offset}` : '';

                const response = await fetch(
                    `${baseUrl}${filterParam}${offsetParam}`,
                    {
                        headers: {
                            'Authorization': 'Bearer patsSDV6hN1uQuYa5.1a11bbf1aaaa458fa84829e84a96d869d135ee8eed1a4a7af3406338ab4be5d1'
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                if (!Array.isArray(data.records)) {
                    throw new Error('Invalid data format received from API');
                }

                records = records.concat(data.records);
                offset = data.offset || '';
                moreRecords = !!offset;
            } catch (err) {
                console.error('Error fetching records:', err);
                setError(err.message);
                moreRecords = false;
            }
        }

        const validRecords = records.filter(record =>
            record &&
            record.fields &&
            record.fields.Link &&
            record.fields.Title
        );

        setLinks(validRecords);
        setLoading(false);
    }, []);

    // Initialize pageViewCount on mount only
    useEffect(() => {
        const initializePageViewCount = () => {
            const storedCount = cookies.pageViewCount;
            if (!storedCount) {
                setCookie('pageViewCount', '1', { path: '/' });
            } else {
                const newCount = parseInt(storedCount) + 1;
                setCookie('pageViewCount', newCount.toString(), { path: '/' });
            }
        };

        initializePageViewCount();
    }, []);

    // Check for button click based on cookie value
    useEffect(() => {
        const count = parseInt(cookies.pageViewCount || '0');
        if (count > 0 && count % 4 === 0) {
            const button = document.getElementById('button');
            if (button) {
                button.click();
            }
        }
    }, [cookies.pageViewCount]);

    // Fetch data on mount
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        let filterFormula = '';

        if (urlParams.has('cat') && urlParams.has('subCat')) {
            filterFormula = `AND({Category}='${urlParams.get('cat')}',FIND('${urlParams.get('subCat')}',{Subcategory}))`;
        }

        fetchAllRecords(filterFormula);
    }, [fetchAllRecords]);

    if (error) {
        return (
            <div className="container mt-5">
                <div className="alert alert-danger" role="alert">
                    Error loading resources: {error}
                </div>
            </div>
        );
    }

    return (
        <div className="container mt-5">
            <div className="row">
                {loading ? (
                    <NoResult isLoading={true} />
                ) : links.length > 0 ? (
                    links.map(link => <LinkCard key={link.id} {...link.fields} />)
                ) : (
                    <NoResult isLoading={false} />
                )}
            </div>
        </div>
    );
};

const LinkCard = ({ Link, Publisher, Title, Author, Year, Type }) => (
    <div className="col-md-4 py-3">
        <div className="card shadow-sm h-100">
            <div className="card-body h-100">
                <h5 className="card-title">{Title}</h5>
                <div className="align-self-end">
                    <p className="card-text">
                        <small className="text-muted">Publisher: {Publisher || 'N/A'}</small>
                    </p>
                    <p className="card-text">
                        <small className="text-muted">Author: {Author || 'N/A'}</small>
                    </p>
                    <p className="card-text">
                        <small className="text-muted">Year: {Year || 'N/A'}</small>
                    </p>
                    <p className="card-text">
                        <small className="text-muted">Type: {Type && <TypeIcon type={Type} />} {Type || 'N/A'}</small>
                    </p>
                </div>
            </div>
            <div className="card-footer">
                <a
                    className="align-self-end btn btn-lg btn-block btn-success align-bottom"
                    href={Link}
                >
                    Go to resource
                </a>
            </div>
        </div>
    </div>
);

export default App;