import React from "react";

const NoResult = ({ isLoading }) => {
    return (
        <div className="col-sm-4 offset-4">
            <h1 id="loader">
                {isLoading ? 'Loading...' : 'No Resources Found'}
            </h1>
        </div>
    );
};

export default NoResult;